import { generatePath } from 'react-router-dom'
import URI from 'urijs'
import type { ILocation } from '@/logic/app/interfaces'
import { history } from '../browserHistory'

export const getCurrentUrl = (): URI => {
  const { pathname, search, hash } = window.location

  return new URI(`${pathname}${search}${hash}`)
}

export const createRouterLink = generatePath

export const changeQueryString = (name: string, value: string | number, shouldRemoveOldValue?: boolean): void => {
  const url = getCurrentUrl()
  if (shouldRemoveOldValue) url.removeQuery(name)

  history.push(url.addQuery({ [name]: value }).toString())
}

export const changePageUrlHash = (hash: string): void => {
  history.push(getCurrentUrl().hash(hash).toString())
}

export const removeQueryParameter = (name: string | string[]) => {
  const url = getCurrentUrl()

  url.removeQuery(name)
  history.push(url.toString())
}

export const getLocationPayload = (href: string) => {
  const url = new URI(href)
  const pathname = url.pathname()

  return {
    hash: url.hash(),
    is404Page: pathname === '/error-404',
    pathname,
    search: url.search(),
    query: url.query(true),
    origin: url.origin(),
    href: url.href(),
    protocol: url.protocol(),
    hostname: url.hostname(),
  }
}

export const getUrlWithQuery = (url: string, query: Record<string, unknown> | null): string => new URI(url).addQuery(query || {}).toString()

export const changeQueryStringForTabs = (name: string, value: string | number, shouldRemoveOldValue?: boolean): void => {
  const url = getCurrentUrl()
  if (shouldRemoveOldValue) url.removeQuery(name)

  history.replace(url.addQuery({ [name]: value }).toString())
}

export function getLocationHref(location: ILocation): string {
  const { pathname, search, hash } = location

  return new URI(`${pathname}${search}${hash}`).toString()
}

export function isValidUrl(url: string) {
  try {
    const isCorrectURL = new URL(url)

    return !!isCorrectURL
  } catch {
    return false
  }
}

import type { FindForVitrinaMutationRequest, FindForVitrinaQueryParams } from '@/api/kubik/auctions'
import { AuctionStatus, findForVitrina, getAuction, getAuctionBets, getAuctionBetsQueryKey, getAuctionQueryKey } from '@/api/kubik/auctions'
import type { TSsrPrefetchQueries } from '@/interfaces'

export const getVitrinaQueryKey = (params?: FindForVitrinaQueryParams) =>
  [{ url: `/v1/auctions/view_for-vitrina` }, ...(params ? [params] : [])] as const

export const betsVitrinaPrefetchSsrQueries: TSsrPrefetchQueries = (routeParams: Record<string, string | undefined>) => {
  const data: FindForVitrinaMutationRequest = {
    status: routeParams?.status ? [routeParams?.status as AuctionStatus] : [AuctionStatus.planned],
  }

  const params: FindForVitrinaQueryParams = {
    offset: routeParams?.offset ? Number(routeParams?.offset) : 0,
    limit: routeParams?.limit ? Number(routeParams?.limit) : 10,
  }

  const keyParams = {
    ...data,
    ...params,
  }

  return [{ queryFn: () => findForVitrina(data, params), queryKey: getVitrinaQueryKey(keyParams) }]
}

export const betsAuctionPrefetchQueries: TSsrPrefetchQueries = (routeParams: Record<'id', string | undefined>) => {
  const params = routeParams.id ? { auctionId: routeParams.id } : { auctionId: '' }

  return [
    { queryFn: () => getAuction(params), queryKey: getAuctionQueryKey(params) },
    { queryFn: () => getAuctionBets(params.auctionId), queryKey: getAuctionBetsQueryKey(params.auctionId) },
  ]
}

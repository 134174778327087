import type { UseMutationOptions, UseMutationResult } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";
import client from "@/api/client/auctions";
import type { ResponseConfig } from "@/api/client/auctions";
import type { CreateAuctionBetMutationRequest, CreateAuctionBetMutationResponse, CreateAuctionBetQueryParams, CreateAuctionBet400, CreateAuctionBet404 } from "../types/CreateAuctionBet";

/**
 * @description Метод предназначен для ставки аукциона по ID аукциона. https://confluence.poleinvest.ru/pages/viewpage.action?pageId=425487733

 * @link /v1/auction/do_create-bet
 */

export function useCreateAuctionBet <TData = CreateAuctionBetMutationResponse, TError = CreateAuctionBet400 | CreateAuctionBet404, TVariables = CreateAuctionBetMutationRequest>(params: CreateAuctionBetQueryParams, options: {
          mutation?: UseMutationOptions<ResponseConfig<TData>, TError, TVariables>,
          client?: Partial<Parameters<typeof client<TData, TError, TVariables>>[0]>,
      } = {}): UseMutationResult<ResponseConfig<TData>, TError, TVariables> {
  const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};

  return useMutation<ResponseConfig<TData>, TError, TVariables>({
    mutationFn: (data) => {
      
      return client<TData, TError, TVariables>({
        method: "post",
        url: `/v1/auction/do_create-bet`,
        data,
        params,
        
        ...clientOptions
      });
    },
    ...mutationOptions
  });
};

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Receiver = {
    type?: Receiver.type;
    value?: string;
};

export namespace Receiver {

    export enum type {
        ORGANIZATION_ID = 'ORGANIZATION_ID',
        EMAIL_ADDRESS = 'EMAIL_ADDRESS',
    }


}

